define('TescoMobile_Algolia/js/model/analytics-tags',['js-storage/js.storage'], function (storage) {
  let localStorage = storage.initNamespaceStorage('mage-cache-storage').localStorage;
  const pageSourceMappings = [{
    className: 'cms-home',
    type: 'homepage'
  }, {
    className: 'cms-page-view',
    type: 'cms'
  }, {
    className: 'catalog-category-view',
    type: 'plp'
  }, {
    className: 'catalog-product-view',
    type: 'pdp'
  }];
  return {
    getPageType: function () {
      const match = pageSourceMappings.find(mapping => document.body.classList.contains(mapping.className));
      return match ? match.type : 'other';
    },
    getUserDevice: function () {
      if (isMobile()) {
        return 'mobile';
      }
      return this.isTablet() ? 'tablet' : 'desktop';
    },
    isTablet: function () {
      let ua = navigator.userAgent || window.opera;
      return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(ua);
    },
    isCustomerLoggedIn: function () {
      let customer = localStorage.get('customer');
      return customer && customer.firstname && customer.fullname;
    },
    getUserState: function () {
      return this.isCustomerLoggedIn() ? 'logged_in' : 'logged_out';
    },
    getTags: function (section) {
      return [section, `user: ${this.getUserState()}`, `device: ${this.getUserDevice()}`, `source: ${this.getPageType()}`];
    }
  };
});

