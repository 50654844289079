define('Algolia_AlgoliaSearch/internals/template/autocomplete/additional-section',[], function () {
  return {
    getNoResultHtml: function (_ref) {
      let html = _ref.html;
      return html`<p>${algoliaConfig.translations.noResults}</p>`;
    },
    getHeaderHtml: function (_ref2) {
      let section = _ref2.section;
      return section.label || section.name;
    },
    getItemHtml: function (_ref3) {
      let item = _ref3.item,
        components = _ref3.components,
        html = _ref3.html,
        section = _ref3.section;
      return html`<a class="aa-ItemLink"
                           href="${algoliaConfig.resultPageUrl}?q=${encodeURIComponent(item.query)}&${section.name}=${encodeURIComponent(item.value)}"
                           data-objectId="${item.objectID}"
                           data-position="${item.position}"
                           data-index="${item.__autocomplete_indexName}"
                           data-queryId="${item.__autocomplete_queryID}">
                ${components.Highlight({
        hit: item,
        attribute: 'value'
      })}
            </a>`;
    },
    getFooterHtml: function () {
      return "";
    }
  };
});

