define('TescoMobile_Algolia/js/template/autocomplete/pages',[], function () {
  return {
    getNoResultHtml: function (_ref) {
      let html = _ref.html;
      return html`<div class="no-results">${algoliaConfig.translations.noResults}</div>`;
    },
    getHeaderHtml: function () {
      return "";
    },
    getItemHtml: function (_ref2) {
      var _item$breadcrumbs;
      let item = _ref2.item,
        components = _ref2.components,
        html = _ref2.html;
      if (item.__autocomplete_queryID) {
        const _algoliaConfig = algoliaConfig,
          ccAnalytics = _algoliaConfig.ccAnalytics;
        const urlForInsights = new URL(item.url);
        if (ccAnalytics.enabled && ccAnalytics.conversionAnalyticsMode !== 'disabled') {
          urlForInsights.searchParams.set('queryID', item.__autocomplete_queryID);
          urlForInsights.searchParams.set('objectID', item.objectID);
          urlForInsights.searchParams.set('indexName', item.__autocomplete_indexName);
        }
        item.urlForInsights = urlForInsights.toString();
      }
      return html`<a class="algoliasearch-autocomplete-hit"
                           href="${item !== null && item !== void 0 && item.__autocomplete_queryID && item !== null && item !== void 0 && item.urlForInsights ? item.urlForInsights : item.url}"
                           data-objectId="${item.objectID}"
                           data-position="${item.position}"
                           data-index="${item.__autocomplete_indexName}"
                           data-queryId="${item.__autocomplete_queryID}">
                <div class="breadcrumb">
                    ${item === null || item === void 0 || (_item$breadcrumbs = item.breadcrumbs) === null || _item$breadcrumbs === void 0 || (_item$breadcrumbs = _item$breadcrumbs.split('|')) === null || _item$breadcrumbs === void 0 ? void 0 : _item$breadcrumbs.map(breadcrumb => html`<span class="path">${breadcrumb}</span>`)}
                </div>
                <div class="info-without-thumb">
                    ${components.Highlight({
        hit: item,
        attribute: 'name'
      })}
                    <div class="details">
                        ${components.Snippet({
        hit: item,
        attribute: 'content'
      })}
                    </div>
                </div>
                <div class="algolia-clearfix"></div>
            </a>`;
    },
    getFooterHtml: function () {
      return "";
    }
  };
});

