/**
 * Copyright © 2020 Redbox Digital Limited. All rights reserved.
 */

define('Magento_Theme/js/model/scroll-shadow-mutation-observer',[
    'jquery'
], function (
    $
) {
    return {
        /**
         * Setting mutation observer, observing is any of the sub elements has changed in the DOM
         * @param id
         * @param items
         * @param item
         * @param callbackFn
         */
        initiate: function (id, items, item, callbackFn) {
            var _self = this;
            // Select the node that will be observed for mutations
            let targetNode = $(id);

            // Options for the observer (which mutations to observe)
            let cfg = {
                childList: true,
                addedNodes: NodeList[1],
                removedNodes: NodeList[0]
            };

            // Callback function to execute when mutations are observed
            let callback = function (mutationsList, observer) {
                callbackFn();
                _self.setShadow(id, items, item);

            };

            // Create an observer instance linked to the callback function
            let observer = new MutationObserver(callback);

            // Start observing the target node for configured mutations
            $.each(targetNode, function (i, node) {
                observer.observe(node, cfg);
            })
        },

        /**
         * Set the shadow at the edge of the screen for vertical scroll
         * @param id
         * @param items
         * @param item
         */
        setShadow: function (id, items, item) {
            var totalWidth = 0,
                windowScreenWidth = $(window).width(),
                scrollWidth,
                paddingGap = 10,
                id = $(id),
                items = $(items),
                item = $(item);

            item.each(function () {
                totalWidth += $(this).outerWidth();
            });

            if($('#algolia-left-container').length > 0) {
                windowScreenWidth = windowScreenWidth - $('#algolia-left-container').outerWidth();
            }

            scrollWidth = (totalWidth - windowScreenWidth) - paddingGap;

            var scrollLeft = items.scrollLeft()
            if (scrollLeft > paddingGap) {
                id.addClass('left-shadow');
            }
            if (scrollLeft <= scrollWidth) {
                id.addClass('right-shadow');
            }

            items.scroll(function () {
                var scrollLeft = $(this).scrollLeft();

                if (scrollLeft < paddingGap) {
                    $(this).parent().removeClass('left-shadow');
                }
                if (scrollLeft > paddingGap) {
                    $(this).parent().addClass('left-shadow');
                    $(this).parent().addClass('right-shadow');
                }
                if (scrollLeft <= scrollWidth) {
                    $(this).parent().addClass('right-shadow');
                } else {
                    $(this).parent().removeClass('right-shadow');
                }
            });
        }
    }
});

