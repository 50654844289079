define('TescoMobile_FamilyBubble/js/instantsearch',['jquery', 'mage/cookies', 'algoliaCommon'], function ($) {
  return function () {
    algolia.registerHook('beforeInstantsearchInit', function (instantsearchOptions, algoliaBundle) {
      const originalSearch = instantsearchOptions.searchFunction;
      instantsearchOptions.searchFunction = function (helper) {
        if (document.querySelectorAll('.page-layout-category-simo').length) {
          if ($.mage.cookies.get('family_bubble_full') === '1' && $.mage.cookies.get('family_bubble_upgrade') !== '1') {
            helper.state.disjunctiveFacetsRefinements.tm_family_bubble_eligible_no_full_price = [true];
          } else {
            helper.state.disjunctiveFacetsRefinements.tm_family_bubble_eligible_full_price = [true];
          }
        }
        originalSearch(helper);
      };
      const transformHit = window.transformHit;
      window.transformHit = function (hit, price_key, helper) {
        var _hit$tm_family_bubble, _hit$tm_family_bubble2, _hit$tm_family_bubble3, _hit$tm_family_bubble4;
        const hitUpdated = transformHit(hit, price_key, helper);
        hitUpdated.__bubbleFullPrice = (hit === null || hit === void 0 || (_hit$tm_family_bubble = hit.tm_family_bubble_eligible) === null || _hit$tm_family_bubble === void 0 ? void 0 : _hit$tm_family_bubble.toLowerCase()) === "yes" && (hit === null || hit === void 0 || (_hit$tm_family_bubble2 = hit.tm_family_bubble_is_fullprice) === null || _hit$tm_family_bubble2 === void 0 ? void 0 : _hit$tm_family_bubble2.toLowerCase()) === "yes";
        hitUpdated.__bubbleFullNoPrice = (hit === null || hit === void 0 || (_hit$tm_family_bubble3 = hit.tm_family_bubble_eligible) === null || _hit$tm_family_bubble3 === void 0 ? void 0 : _hit$tm_family_bubble3.toLowerCase()) === "yes" && (hit === null || hit === void 0 || (_hit$tm_family_bubble4 = hit.tm_family_bubble_is_fullprice) === null || _hit$tm_family_bubble4 === void 0 ? void 0 : _hit$tm_family_bubble4.toLowerCase()) === "no";
        return hitUpdated;
      };
      return instantsearchOptions;
    });
  };
});

