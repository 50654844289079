define('Algolia_AlgoliaSearch/internals/template/autocomplete/categories',[], function () {
  return {
    getNoResultHtml: function (_ref) {
      let html = _ref.html;
      return html`<p>${algoliaConfig.translations.noResults}</p>`;
    },
    getHeaderHtml: function (_ref2) {
      let section = _ref2.section;
      return section.label;
    },
    getItemHtml: function (_ref3) {
      let item = _ref3.item,
        components = _ref3.components,
        html = _ref3.html;
      return html`<a class="algoliasearch-autocomplete-hit"
                            href="${item.url}"
                            data-objectId="${item.objectID}"
                            data-position="${item.position}"
                            data-index="${item.__autocomplete_indexName}"
                            data-queryId="${item.__autocomplete_queryID}">
                ${components.Highlight({
        hit: item,
        attribute: 'path'
      })} (${item.product_count})
            </a>`;
    },
    getFooterHtml: function () {
      return "";
    }
  };
});

