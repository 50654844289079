define('TescoMobile_BoPo/js/template/autocomplete/products-mixin',['mage/utils/wrapper', 'mage/translate', 'algoliaCommon'], function (wrapper, $t) {
  return function (productsHtml) {
    var _algoliaConfig$autoco;
    if (!((_algoliaConfig$autoco = algoliaConfig.autocomplete) !== null && _algoliaConfig$autoco !== void 0 && _algoliaConfig$autoco.isBoPoEnabled)) {
      return productsHtml;
    }
    const getBackorderDeliveryHtml = (item, html) => {
      var _item$back_order;
      return item !== null && item !== void 0 && (_item$back_order = item.back_order) !== null && _item$back_order !== void 0 && _item$back_order.tm_device_sold_as_backorder ? html`
                <div class="delivery-date backorder">
                    <p>${$t('Temporarily out of stock')}</p>
                    <p>${$t('Delivered by')} <strong>${item.back_order.tm_device_backorder_delivery_date_formatted}</strong></p>
                </div>` : '';
    };
    const getPreorderDeliveryHtml = (item, html) => {
      var _item$pre_order, _item$pre_order2, _item$pre_order3;
      return item !== null && item !== void 0 && (_item$pre_order = item.pre_order) !== null && _item$pre_order !== void 0 && _item$pre_order.tm_device_sold_as_preorder ? html`
                <div class="estimated-delivery preorder">
                    ${item !== null && item !== void 0 && (_item$pre_order2 = item.pre_order) !== null && _item$pre_order2 !== void 0 && _item$pre_order2.in_stock && item.pre_order.tm_device_preorder_street_date_formatted ? html`<p class="in-stock">${$t('Delivered')}${` `}
                                <strong>${item.pre_order.tm_device_preorder_street_date_formatted}</strong></p>` : (item === null || item === void 0 || (_item$pre_order3 = item.pre_order) === null || _item$pre_order3 === void 0 ? void 0 : _item$pre_order3.in_stock) === false ? html`<p class="out-of-stock">${$t('Out of stock for pre-order')}</p>` : ''}
                </div>` : '';
    };
    const getPreorderLabelHtml = (item, html) => {
      var _item$pre_order4, _item$pre_order5;
      return item !== null && item !== void 0 && (_item$pre_order4 = item.pre_order) !== null && _item$pre_order4 !== void 0 && _item$pre_order4.tm_device_sold_as_preorder && item !== null && item !== void 0 && (_item$pre_order5 = item.pre_order) !== null && _item$pre_order5 !== void 0 && _item$pre_order5.in_stock ? productsHtml.getLabelHtml(html, $t('Pre-order'), 'preorder') : '';
    };
    productsHtml.getProductLabelHtml = wrapper.wrapSuper(productsHtml.getProductLabelHtml, function (item, html) {
      return html`${this._super(item, html)}${getPreorderLabelHtml(item, html)}`;
    });
    productsHtml.getAfterPromoHtml = wrapper.wrapSuper(productsHtml.getAfterPromoHtml, function (item, html) {
      const output = this._super(item, html);
      return html`${output}${getBackorderDeliveryHtml(item, html)}${getPreorderDeliveryHtml(item, html)}`;
    });
    return productsHtml;
  };
});

