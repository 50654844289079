function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
define('Algolia_AlgoliaSearch/autocomplete',['jquery', 'algoliaBundle', 'pagesHtml', 'categoriesHtml', 'productsHtml', 'suggestionsHtml', 'additionalHtml', 'algoliaCommon', 'algoliaInsights', 'algoliaHooks', 'domReady!'], function ($, algoliaBundle, pagesHtml, categoriesHtml, productsHtml, suggestionsHtml, additionalHtml) {
  const DEFAULT_HITS_PER_SECTION = 2;
  const DEBOUNCE_MS = algoliaConfig.autocomplete.debounceMilliseconds;
  const MIN_SEARCH_LENGTH_CHARS = algoliaConfig.autocomplete.minimumCharacters;
  let suggestionSection = false;
  let algoliaFooter;
  if (typeof algoliaConfig === 'undefined' || !algoliaConfig.autocomplete.enabled) {
    return;
  }
  const searchClient = algoliaBundle.algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
  searchClient.addAlgoliaAgent('Magento2 integration (' + algoliaConfig.extensionVersion + ')');
  const transformAutocompleteHit = function (hit, price_key, helper) {
    if (Array.isArray(hit.categories)) hit.categories = hit.categories.join(', ');
    if (hit._highlightResult.categories_without_path && Array.isArray(hit.categories_without_path)) {
      hit.categories_without_path = $.map(hit._highlightResult.categories_without_path, function (category) {
        return category.value;
      });
      hit.categories_without_path = hit.categories_without_path.join(', ');
    }
    let matchedColors = [];
    if (helper && algoliaConfig.useAdaptiveImage === true) {
      if (hit.images_data && helper.state.facetsRefinements.color) {
        matchedColors = helper.state.facetsRefinements.color.slice(0);
      }
      if (hit.images_data && helper.state.disjunctiveFacetsRefinements.color) {
        matchedColors = helper.state.disjunctiveFacetsRefinements.color.slice(0);
      }
    }
    if (Array.isArray(hit.color)) {
      let colors = [];
      $.each(hit._highlightResult.color, function (i, color) {
        if (color.matchLevel === undefined || color.matchLevel === 'none') {
          return;
        }
        colors.push(color.value);
        if (algoliaConfig.useAdaptiveImage === true) {
          const matchedColor = color.matchedWords.join(' ');
          if (hit.images_data && color.fullyHighlighted && color.fullyHighlighted === true) {
            matchedColors.push(matchedColor);
          }
        }
      });
      colors = colors.join(', ');
      hit._highlightResult.color = {
        value: colors
      };
    } else {
      if (hit._highlightResult.color && hit._highlightResult.color.matchLevel === 'none') {
        hit._highlightResult.color = {
          value: ''
        };
      }
    }
    if (algoliaConfig.useAdaptiveImage === true) {
      $.each(matchedColors, function (i, color) {
        color = color.toLowerCase();
        if (hit.images_data[color]) {
          hit.image_url = hit.images_data[color];
          hit.thumbnail_url = hit.images_data[color];
          return false;
        }
      });
    }
    if (hit._highlightResult.color && hit._highlightResult.color.value && hit.categories_without_path) {
      if (hit.categories_without_path.indexOf('<em>') === -1 && hit._highlightResult.color.value.indexOf('<em>') !== -1) {
        hit.categories_without_path = '';
      }
    }
    if (Array.isArray(hit._highlightResult.name)) hit._highlightResult.name = hit._highlightResult.name[0];
    if (Array.isArray(hit.price)) {
      hit.price = hit.price[0];
      if (hit['price'] !== undefined && price_key !== '.' + algoliaConfig.currencyCode + '.default' && hit['price'][algoliaConfig.currencyCode][price_key.substr(1) + '_formated'] !== hit['price'][algoliaConfig.currencyCode]['default_formated']) {
        hit['price'][algoliaConfig.currencyCode][price_key.substr(1) + '_original_formated'] = hit['price'][algoliaConfig.currencyCode]['default_formated'];
      }
      if (hit['price'][algoliaConfig.currencyCode]['default_original_formated'] && hit['price'][algoliaConfig.currencyCode]['special_to_date']) {
        const priceExpiration = hit['price'][algoliaConfig.currencyCode]['special_to_date'];
        if (algoliaConfig.now > priceExpiration + 1) {
          hit['price'][algoliaConfig.currencyCode]['default_formated'] = hit['price'][algoliaConfig.currencyCode]['default_original_formated'];
          hit['price'][algoliaConfig.currencyCode]['default_original_formated'] = false;
        }
      }
    }
    const action = algoliaConfig.instant.addToCartParams.action + 'product/' + hit.objectID + '/';
    const correctFKey = getCookie('form_key');
    if (correctFKey != "" && algoliaConfig.instant.addToCartParams.formKey != correctFKey) {
      algoliaConfig.instant.addToCartParams.formKey = correctFKey;
    }
    hit.addToCart = {
      'action': action,
      'uenc': AlgoliaBase64.mageEncode(action),
      'formKey': algoliaConfig.instant.addToCartParams.formKey
    };
    if (hit.__autocomplete_queryID) {
      hit.urlForInsights = hit.url;
      if (algoliaConfig.ccAnalytics.enabled && algoliaConfig.ccAnalytics.conversionAnalyticsMode !== 'disabled') {
        const insightsDataUrlString = $.param({
          queryID: hit.__autocomplete_queryID,
          objectID: hit.objectID,
          indexName: hit.__autocomplete_indexName
        });
        if (hit.url.indexOf('?') > -1) {
          hit.urlForInsights += insightsDataUrlString;
        } else {
          hit.urlForInsights += '?' + insightsDataUrlString;
        }
      }
    }
    return hit;
  };
  const getNavigatorUrl = function (url) {
    if (algoliaConfig.autocomplete.isNavigatorEnabled) {
      return url;
    }
  };
  const buildAutocompleteSource = function (section, searchClient) {
    let options = {
      hitsPerPage: section.hitsPerPage || DEFAULT_HITS_PER_SECTION,
      analyticsTags: 'autocomplete',
      clickAnalytics: true,
      distinct: true
    };
    const getItemUrl = _ref => {
      let item = _ref.item;
      return getNavigatorUrl(item.url);
    };
    const transformResponse = _ref2 => {
      let results = _ref2.results,
        hits = _ref2.hits;
      const resDetail = results[0];
      return hits.map(res => {
        return res.map((hit, i) => {
          return _objectSpread(_objectSpread({}, hit), {}, {
            query: resDetail.query,
            position: i + 1
          });
        });
      });
    };
    const defaultSectionIndex = `${algoliaConfig.indexName}_${section.name}`;
    const source = {
      sourceId: section.name,
      options,
      getItemUrl,
      transformResponse,
      indexName: defaultSectionIndex
    };
    if (section.name === "products") {
      options.facets = ['categories.level0'];
      options.numericFilters = 'visibility_search=1';
      options.ruleContexts = ['magento_filters', ''];
      options = algolia.triggerHooks('beforeAutocompleteProductSourceOptions', options);
      source.options = algolia.triggerHooks('afterAutocompleteProductSourceOptions', options);
      source.templates = {
        noResults(_ref3) {
          let html = _ref3.html;
          return productsHtml.getNoResultHtml({
            html
          });
        },
        header(_ref4) {
          let items = _ref4.items,
            html = _ref4.html;
          return productsHtml.getHeaderHtml({
            items,
            html
          });
        },
        item(_ref5) {
          let item = _ref5.item,
            components = _ref5.components,
            html = _ref5.html;
          if (suggestionSection) {
            $('.aa-Panel').addClass('productColumn2');
            $('.aa-Panel').removeClass('productColumn1');
          } else {
            $('.aa-Panel').removeClass('productColumn2');
            $('.aa-Panel').addClass('productColumn1');
          }
          if (algoliaFooter && algoliaFooter !== undefined && algoliaFooter !== null && $('#algoliaFooter').length === 0) {
            $('.aa-PanelLayout').append(algoliaFooter);
          }
          const _data = transformAutocompleteHit(item, algoliaConfig.priceKey);
          return productsHtml.getItemHtml({
            item: _data,
            components,
            html
          });
        },
        footer(_ref6) {
          let items = _ref6.items,
            html = _ref6.html;
          const resultDetails = {};
          if (items.length) {
            const firstItem = items[0];
            resultDetails.allDepartmentsUrl = algoliaConfig.resultPageUrl + '?q=' + encodeURIComponent(firstItem.query);
            resultDetails.nbHits = firstItem.nbHits;
            if (algoliaConfig.facets.find(facet => facet.attribute === 'categories')) {
              let allCategories = [];
              if (typeof firstItem.allCategories !== 'undefined') {
                allCategories = Object.keys(firstItem.allCategories).map(key => {
                  const url = resultDetails.allDepartmentsUrl + '&categories=' + encodeURIComponent(key);
                  return {
                    name: key,
                    value: firstItem.allCategories[key],
                    url
                  };
                });
              }
              allCategories.sort((a, b) => b.value - a.value);
              resultDetails.allCategories = allCategories.slice(0, 2);
            }
          }
          return productsHtml.getFooterHtml(_objectSpread({
            html
          }, resultDetails));
        }
      };
      source.transformResponse = _ref7 => {
        let results = _ref7.results,
          hits = _ref7.hits;
        const resDetail = results[0];
        return hits.map(res => {
          return res.map((hit, i) => {
            return _objectSpread(_objectSpread({}, hit), {}, {
              nbHits: resDetail.nbHits,
              allCategories: resDetail.facets['categories.level0'],
              query: resDetail.query,
              position: i + 1
            });
          });
        });
      };
    } else if (section.name === "categories") {
      if (section.name === "categories" && algoliaConfig.showCatsNotIncludedInNavigation === false) {
        options.numericFilters = 'include_in_menu=1';
      }
      source.templates = {
        noResults(_ref8) {
          let html = _ref8.html;
          return categoriesHtml.getNoResultHtml({
            html
          });
        },
        header(_ref9) {
          let html = _ref9.html,
            items = _ref9.items;
          return categoriesHtml.getHeaderHtml({
            section,
            html,
            items
          });
        },
        item(_ref10) {
          let item = _ref10.item,
            components = _ref10.components,
            html = _ref10.html;
          return categoriesHtml.getItemHtml({
            item,
            components,
            html
          });
        },
        footer(_ref11) {
          let html = _ref11.html,
            items = _ref11.items;
          return categoriesHtml.getFooterHtml({
            section,
            html,
            items
          });
        }
      };
    } else if (section.name === "pages") {
      source.templates = {
        noResults(_ref12) {
          let html = _ref12.html;
          return pagesHtml.getNoResultHtml({
            html
          });
        },
        header(_ref13) {
          let html = _ref13.html,
            items = _ref13.items;
          return pagesHtml.getHeaderHtml({
            section,
            html,
            items
          });
        },
        item(_ref14) {
          let item = _ref14.item,
            components = _ref14.components,
            html = _ref14.html;
          return pagesHtml.getItemHtml({
            item,
            components,
            html
          });
        },
        footer(_ref15) {
          let html = _ref15.html,
            items = _ref15.items;
          return pagesHtml.getFooterHtml({
            section,
            html,
            items
          });
        }
      };
    } else {
      source.indexName = `${algoliaConfig.indexName}_section_${section.name}`;
      source.templates = {
        noResults(_ref16) {
          let html = _ref16.html;
          return additionalHtml.getNoResultHtml({
            html
          });
        },
        header(_ref17) {
          let html = _ref17.html,
            items = _ref17.items;
          return additionalHtml.getHeaderHtml({
            section,
            html,
            items
          });
        },
        item(_ref18) {
          let item = _ref18.item,
            components = _ref18.components,
            html = _ref18.html;
          return additionalHtml.getItemHtml({
            item,
            components,
            html,
            section
          });
        },
        footer(_ref19) {
          let html = _ref19.html,
            items = _ref19.items;
          return additionalHtml.getFooterHtml({
            section,
            html,
            items
          });
        }
      };
    }
    return source;
  };
  const buildSuggestionsPlugin = function () {
    return algoliaBundle.createQuerySuggestionsPlugin.createQuerySuggestionsPlugin({
      searchClient,
      indexName: `${algoliaConfig.indexName}_suggestions`,
      getSearchParams() {
        return {
          hitsPerPage: algoliaConfig.autocomplete.nbOfQueriesSuggestions,
          clickAnalytics: true
        };
      },
      transformSource(_ref20) {
        let source = _ref20.source;
        return _objectSpread(_objectSpread({}, source), {}, {
          getItems(_ref21) {
            let query = _ref21.query;
            const items = filterMinChars(query, source.getItems());
            const oldTransform = items.transformResponse;
            items.transformResponse = arg => {
              const hits = oldTransform ? oldTransform(arg) : arg.hits;
              return hits.map((hit, i) => {
                return _objectSpread(_objectSpread({}, hit), {}, {
                  position: i + 1
                });
              });
            };
            return items;
          },
          getItemUrl(_ref22) {
            let item = _ref22.item;
            return getNavigatorUrl(algoliaConfig.resultPageUrl + `?q=${item.query}`);
          },
          templates: {
            noResults(_ref23) {
              let html = _ref23.html;
              return suggestionsHtml.getNoResultHtml({
                html
              });
            },
            header(_ref24) {
              let html = _ref24.html,
                items = _ref24.items;
              return suggestionsHtml.getHeaderHtml({
                html,
                items
              });
            },
            item(_ref25) {
              let item = _ref25.item,
                components = _ref25.components,
                html = _ref25.html;
              return suggestionsHtml.getItemHtml({
                item,
                components,
                html
              });
            },
            footer(_ref26) {
              let html = _ref26.html,
                items = _ref26.items;
              return suggestionsHtml.getFooterHtml({
                html,
                items
              });
            }
          }
        });
      }
    });
  };
  const filterMinChars = (query, result) => {
    return query.length >= MIN_SEARCH_LENGTH_CHARS ? result : [];
  };
  const debouncePromise = (fn, time) => {
    let timerId = undefined;
    return function debounced() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      if (timerId) {
        clearTimeout(timerId);
      }
      return new Promise(resolve => {
        timerId = setTimeout(() => resolve(fn(...args)), time);
      });
    };
  };
  const debounced = debouncePromise(items => Promise.resolve(items), DEBOUNCE_MS);
  if (algoliaConfig.autocomplete.nbOfCategoriesSuggestions > 0) {
    algoliaConfig.autocomplete.sections.unshift({
      hitsPerPage: algoliaConfig.autocomplete.nbOfCategoriesSuggestions,
      label: algoliaConfig.translations.categories,
      name: "categories"
    });
  }
  if (algoliaConfig.autocomplete.nbOfProductsSuggestions > 0) {
    algoliaConfig.autocomplete.sections.unshift({
      hitsPerPage: algoliaConfig.autocomplete.nbOfProductsSuggestions,
      label: algoliaConfig.translations.products,
      name: "products"
    });
  }
  let sources = algoliaConfig.autocomplete.sections.map(section => buildAutocompleteSource(section, searchClient));
  sources = algolia.triggerHooks('beforeAutocompleteSources', sources, searchClient);
  sources = algolia.triggerHooks('afterAutocompleteSources', sources, searchClient);
  let plugins = [];
  if (algoliaConfig.autocomplete.nbOfQueriesSuggestions > 0) {
    suggestionSection = true;
    plugins.push(buildSuggestionsPlugin());
  }
  plugins = algolia.triggerHooks('afterAutocompletePlugins', plugins, searchClient);
  let autocompleteConfig = [];
  let options = algolia.triggerHooks('beforeAutocompleteOptions', {});
  options = _objectSpread(_objectSpread({}, options), {}, {
    container: algoliaConfig.autocomplete.selector,
    placeholder: algoliaConfig.translations.placeholder,
    debug: algoliaConfig.autocomplete.isDebugEnabled,
    detachedMediaQuery: 'none',
    onSubmit(data) {
      if (data.state.query && data.state.query !== null && data.state.query !== "") {
        window.location.href = algoliaConfig.resultPageUrl + `?q=${encodeURIComponent(data.state.query)}`;
      }
    },
    getSources(_ref27) {
      let query = _ref27.query;
      return filterMinChars(query, debounced(autocompleteConfig));
    },
    shouldPanelOpen(_ref28) {
      let state = _ref28.state;
      return state.query.length >= MIN_SEARCH_LENGTH_CHARS;
    }
  });
  if (isMobile() === true) {
    options.debug = true;
  }
  if (algoliaConfig.removeBranding === false) {
    algoliaFooter = `<div id="algoliaFooter" class="footer_algolia"><span class="algolia-search-by-label">${algoliaConfig.translations.searchBy}</span><a href="https://www.algolia.com/?utm_source=magento&utm_medium=link&utm_campaign=magento_autocompletion_menu" title="${algoliaConfig.translations.searchBy} Algolia" target="_blank"><img src="${algoliaConfig.urls.logo}" alt="${algoliaConfig.translations.searchBy} Algolia" /></a></div>`;
  }
  if (typeof algoliaHookBeforeAutocompleteStart === 'function') {
    console.warn('Deprecated! You are using an old API for Algolia\'s front end hooks. ' + 'Please, replace your hook method with new hook API. ' + 'More information you can find on https://www.algolia.com/doc/integration/magento-2/customize/custom-front-end-events/');
    const hookResult = algoliaHookBeforeAutocompleteStart(sources, options, searchClient);
    sources = hookResult.shift();
    options = hookResult.shift();
  }
  sources.forEach(data => {
    if (!data.sourceId) {
      console.error("Algolia Autocomplete: sourceId is required for custom sources");
      return;
    }
    const getItems = _ref29 => {
      let query = _ref29.query;
      return algoliaBundle.getAlgoliaResults(_objectSpread({
        searchClient,
        queries: [{
          query,
          indexName: data.indexName,
          params: data.options
        }]
      }, data.transformResponse && {
        transformResponse: data.transformResponse
      }));
    };
    const fallbackTemplates = {
      noResults: () => 'No results',
      header: () => data.sourceId,
      item: _ref30 => {
        let item = _ref30.item;
        console.error(`Algolia Autocomplete: No template defined for source "${data.sourceId}"`);
        return '[ITEM TEMPLATE MISSING]';
      }
    };
    autocompleteConfig.push(_objectSpread({
      sourceId: data.sourceId,
      getItems,
      templates: _objectSpread(_objectSpread({}, fallbackTemplates), data.templates || {})
    }, data.getItemUrl && {
      getItemUrl: data.getItemUrl
    }));
  });
  options.plugins = plugins;
  options = algolia.triggerHooks('afterAutocompleteOptions', options);
  let algoliaAutocompleteInstance = algoliaBundle.autocomplete(options);
  algoliaAutocompleteInstance = algolia.triggerHooks('afterAutocompleteStart', algoliaAutocompleteInstance);
  if (algoliaConfig.ccAnalytics.enabled) {
    $(document).on('click', '.algoliasearch-autocomplete-hit', function () {
      const $this = $(this);
      if ($this.data('clicked')) return;
      const objectId = $this.attr('data-objectId');
      const indexName = $this.attr('data-index');
      const queryId = $this.attr('data-queryId');
      const position = $this.attr('data-position');
      let useCookie = algoliaConfig.cookieConfiguration.cookieRestrictionModeEnabled ? !!getCookie(algoliaConfig.cookieConfiguration.consentCookieName) : true;
      if (useCookie !== false) {
        algoliaInsights.initializeAnalytics();
        const eventData = algoliaInsights.buildEventData('Clicked', objectId, indexName, position, queryId);
        algoliaInsights.trackClick(eventData);
        $this.attr('data-clicked', true);
      }
    });
  }
  if (algoliaConfig.autocomplete.isNavigatorEnabled) {
    $("body").append('<style>.aa-Item[aria-selected="true"]{background-color: #f2f2f2;}</style>');
  }
});

