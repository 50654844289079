define('TescoMobile_FreeGift/js/template/autocomplete/products-mixin',['mage/utils/wrapper', 'algoliaCommon'], function (wrapper) {
  return function (productsHtml) {
    var _algoliaConfig$autoco;
    if (!((_algoliaConfig$autoco = algoliaConfig.autocomplete) !== null && _algoliaConfig$autoco !== void 0 && _algoliaConfig$autoco.isFreeGiftEnabled)) {
      return productsHtml;
    }
    const getFreeGiftLabel = (item, html) => {
      var _item$free_gift;
      return item !== null && item !== void 0 && (_item$free_gift = item.free_gift) !== null && _item$free_gift !== void 0 && _item$free_gift.label ? html`
                <div class="free-gift__plp-wrapper">
                    <span class="free-gift label"
                          data-clubcard-points="${item.free_gift.clubcard_points}"
                          data-voucher-value="${item.free_gift.voucher_value}">
                        <span class="text">${item.free_gift.label}</span>
                    </span>
                </div>
                <div class="free-gift-tooltip" style="display: none;">
                    <div class="close"><span class="icon icon-close"></span></div>
                    <div><h2>Test</h2></div>
                </div>
            ` : '';
    };
    productsHtml.getPreContentHtml = wrapper.wrapSuper(productsHtml.getPreContentHtml, function (item, html) {
      const output = this._super(item, html);
      return html`${output}${getFreeGiftLabel(item, html)}`;
    });
    return productsHtml;
  };
});

