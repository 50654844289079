define('TescoMobile_Algolia/js/template/autocomplete/lucid',[], function () {
  return {
    getNoResultHtml(_ref) {
      let html = _ref.html;
      return html`<div class="no-results">${algoliaConfig.translations.noResults}</div>`;
    },
    getHeaderHtml() {
      return "";
    },
    getItemHtml(_ref2) {
      var _item$breadcrumb;
      let item = _ref2.item,
        html = _ref2.html;
      return html`
                <div class="algoliasearch-autocomplete-hit"
                     data-objectId="${item.objectID}"
                     data-position="${item.position}"
                     data-index="${item.__autocomplete_indexName}"
                     data-queryId="${item.__autocomplete_queryID}">
                    <div class="breadcrumb">
                        ${(_item$breadcrumb = item.breadcrumb) === null || _item$breadcrumb === void 0 || (_item$breadcrumb = _item$breadcrumb.split('|')) === null || _item$breadcrumb === void 0 ? void 0 : _item$breadcrumb.map(breadcrumb => html`<span class="path">${breadcrumb}</span>`)}
                    </div>
                    <a href="${item.link}" class="hit-name">${item.meta}</a>
                </div>`;
    },
    getFooterHtml() {
      return "";
    }
  };
});

