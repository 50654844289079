define('TescoMobile_Algolia/js/model/instantsearch/position-breakout-tiles',[], function () {
  return {
    positionBreakoutTiles: function () {
      var _algoliaConfig, _algoliaConfig2;
      if (!((_algoliaConfig = algoliaConfig) !== null && _algoliaConfig !== void 0 && (_algoliaConfig = _algoliaConfig.breakoutTile) !== null && _algoliaConfig !== void 0 && _algoliaConfig.containerSelector) || !((_algoliaConfig2 = algoliaConfig) !== null && _algoliaConfig2 !== void 0 && (_algoliaConfig2 = _algoliaConfig2.breakoutTile) !== null && _algoliaConfig2 !== void 0 && _algoliaConfig2.tileSelector)) {
        return;
      }
      const tiles = document.querySelectorAll(algoliaConfig.breakoutTile.tileSelector);
      const destination = document.querySelector(algoliaConfig.breakoutTile.containerSelector);
      if (!destination || !tiles.length) {
        return;
      }
      tiles.forEach(function (tile) {
        this.positionBreakoutTile(tile, destination);
      }.bind(this));
    },
    positionBreakoutTile: function (tile, destination) {
      try {
        const properties = JSON.parse(tile.getAttribute('data-properties'));
        const position = parseInt(properties.position);
        const targetPosition = position - 1 < 0 ? 0 : position - 1;
        const referenceNode = destination.childNodes[targetPosition];
        if (referenceNode) {
          destination.insertBefore(tile, referenceNode);
        } else {
          destination.appendChild(tile);
        }
      } catch (e) {
        console.error('Error moving breakout tile:', e);
      }
    }
  };
});

