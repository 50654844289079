define('TescoMobile_DataLayer/js/template/autocomplete/wrapper-mixin',['jquery', 'mage/utils/wrapper'], function ($, wrapper) {
  'use strict';

  return function (autocompleteWrapper) {
    autocompleteWrapper.getHtml = wrapper.wrapSuper(autocompleteWrapper.getHtml, function (_ref) {
      var _state$collections;
      let html = _ref.html,
        sections = _ref.sections,
        state = _ref.state;
      if (((_state$collections = state.collections) === null || _state$collections === void 0 || (_state$collections = _state$collections.find(_ref2 => {
        let source = _ref2.source;
        return source.sourceId === 'products';
      })) === null || _state$collections === void 0 ? void 0 : _state$collections.items.length) > 0) {
        $('body').trigger('dl-autocomplete-products');
      } else {
        $('body').trigger('dl-autocomplete-no-results');
      }
      return this._super({
        html,
        sections,
        state
      });
    });
    return autocompleteWrapper;
  };
});

