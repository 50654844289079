define('TescoMobile_Algolia/js/template/autocomplete/wrapper',[], function () {
  return {
    getHtml(_ref) {
      let html = _ref.html,
        sections = _ref.sections,
        state = _ref.state;
      const autocompleteTabLabels = algoliaConfig.translations.autocompleteTabLabels;
      return html`
                <div class="aa-PanelLayout aa-Panel--scrollable">
                    <div class="autocomplete-wrapper">
                        <div class="tabs">
                            <div class="tabs-inner">
                                ${state.collections.map((_ref2, i) => {
        var _items$0$nbHits, _items$;
        let source = _ref2.source,
          items = _ref2.items;
        const sourceId = source.sourceId;
        return sourceId && autocompleteTabLabels[sourceId] ? html`<span class="tab ${i === 0 ? 'active' : ''}"
                                                     data-source-id="${sourceId}">${autocompleteTabLabels[sourceId]}
                                                <span class="count">(${(_items$0$nbHits = (_items$ = items[0]) === null || _items$ === void 0 ? void 0 : _items$.nbHits) !== null && _items$0$nbHits !== void 0 ? _items$0$nbHits : 0})</span></span>` : '';
      })}
                                <span class="algolia-close">
                                    <span class="icon icon-close"></span>
                                    <div>Close</div>
                                </span>
                            </div>
                        </div>
                        ${sections}
                    </div>
                </div>`;
    }
  };
});

