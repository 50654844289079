define('TescoMobile_DataLayer/js/algolia',['jquery', 'algoliaBundle', 'TescoMobile_DataLayer/js/algolia-hits-preparator', 'algoliaCommon'], function ($, algoliaBundle, DataLayerAlgoliaHitsPreparator) {
  return function () {
    algolia.registerHook('beforeInstantsearchInit', function (instantsearchOptions, algoliaBundle) {
      $(function ($) {
        var transformHit = window.transformHit;
        window.transformHit = function (hit, price_key, helper) {
          var _hitUpdated$colors, _hitUpdated$promotion, _hitUpdated$tm_produc;
          var hitUpdated = transformHit(hit, price_key, helper),
            sku,
            manufacturer;
          if (window.searchResult === undefined) {
            window.searchResult = [];
            window.searchResult['product_group_id'] = [];
            window.searchResult['product_type'] = [];
            window.searchResult['product_brand'] = [];
            window.searchResult['product_name'] = [];
            window.searchResult['product_list_position'] = [];
            window.searchResult['product_colour_out_of_stock'] = [];
            window.searchResult['product_has_offer'] = [];
            window.searchResult['product_stock_status'] = [];
          }
          sku = hitUpdated.sku && hitUpdated.sku.length ? hitUpdated.sku[0].toLowerCase() : '';
          if (window.searchResult['product_group_id'].indexOf(sku) !== -1) {
            return hitUpdated;
          }
          manufacturer = hitUpdated.manufacturer && hitUpdated.manufacturer.length ? hitUpdated.manufacturer[0].toLowerCase() : '';
          window.searchResult['product_group_id'].push(sku);
          window.searchResult['product_brand'].push(manufacturer);
          window.searchResult['product_name'].push(hitUpdated.name.toLowerCase());
          window.searchResult['product_list_position'].push(hitUpdated.__position.toString());
          var outOfStockColors = [];
          hitUpdated === null || hitUpdated === void 0 || (_hitUpdated$colors = hitUpdated.colors) === null || _hitUpdated$colors === void 0 ? void 0 : _hitUpdated$colors.forEach(function (e) {
            if (!e.in_stock && !!e.label) {
              outOfStockColors.push(e.label.toLowerCase());
            }
          });
          window.searchResult['product_colour_out_of_stock'].push(outOfStockColors.join('|'));
          window.searchResult['product_has_offer'].push((hitUpdated === null || hitUpdated === void 0 || (_hitUpdated$promotion = hitUpdated.promotions) === null || _hitUpdated$promotion === void 0 ? void 0 : _hitUpdated$promotion.length) > 0 || (_hitUpdated$tm_produc = hitUpdated.tm_product_label) !== null && _hitUpdated$tm_produc !== void 0 && _hitUpdated$tm_produc.length ? '1' : '0');
          window.searchResult['product_stock_status'].push(Object.keys(hitUpdated.price).length > 0 ? 'in stock' : 'no stock');
          return hitUpdated;
        };
      });
      return instantsearchOptions;
    });
    algolia.registerHook('afterAutocompleteStart', function (algoliaAutocompleteInstance) {
      $('body').on('autocomplete:updated', function (e) {
        $('body').trigger('autocomplete:updated');
      });
      return algoliaAutocompleteInstance;
    });
    algolia.registerHook('afterAutocompleteSources', function (sources, algoliaClient) {
      return sources.map(source => {
        if (source.sourceId === 'products') {
          const originalItemTemplate = source.templates.item;
          source.templates.item = function (params) {
            var _algolia;
            const item = params.item;
            let lastResults = {
                nbHits: item.nbHits,
                nbPages: (_algolia = algolia) === null || _algolia === void 0 || (_algolia = _algolia.autoCompleteCounts) === null || _algolia === void 0 ? void 0 : _algolia.lucid,
                searchTerm: $('.page-header input.aa-Input').val()
              },
              previousLastResults = !!$.data(document.body, 'algolia-overlay-search-previous-results') ? $.data(document.body, 'algolia-overlay-search-previous-results') : {
                nbHits: 0,
                nbPages: 0,
                searchTerm: ''
              };
            if (lastResults.nbHits !== previousLastResults.nbHits || lastResults.nbPages !== previousLastResults.nbPages || lastResults.searchTerm !== previousLastResults.searchTerm) {
              DataLayerAlgoliaHitsPreparator.resetPosition();
              previousLastResults = lastResults;
              $.data(document.body, 'algolia-overlay-search-previous-results', previousLastResults);
            }
            DataLayerAlgoliaHitsPreparator.prepareHitUpdatedData(item, lastResults);
            return originalItemTemplate(params);
          };
        }
        return source;
      });
    });
    algolia.registerHook('beforeWidgetInitialization', function (allWidgetConfiguration, algoliaBundle) {
      window.transformItemsOriginal = allWidgetConfiguration.infiniteHits.transformItems;
      allWidgetConfiguration.infiniteHits.transformItems = function (items) {
        var result = window.transformItemsOriginal(items),
          sortByValue = $('select.ais-SortBy-select option:selected').val(),
          currentProducts = getWindowHitsUpdatedProductSkus(),
          productSkus = JSON.stringify(currentProducts),
          previousSkuList = $.data(document.body, 'product-list-sku');
        if (!sortByValue || !$.data(document.body, 'sort-order-initialized') || productSkus === previousSkuList) {
          return result;
        }
        $('body').trigger('algolia-transform-items-completed');
        $.data(document.body, 'product-list-sku', productSkus);
        return result;
      };
      return allWidgetConfiguration;
    });
    algolia.registerHook('afterInstantsearchStart', function (search, algoliaBundle) {
      search.on('render', function () {
        $('body').trigger('algolia-refinements-updated');
      });
      return search;
    });
    function getWindowHitsUpdatedProductSkus() {
      var productSkus = [];
      $.each(window.hitsUpdated, function (key, hit) {
        productSkus.push(hit.product_group_id);
      });
      return productSkus;
    }
  };
});

