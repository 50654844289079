function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
define('Algolia_AlgoliaSearch/instantsearch',['jquery', 'algoliaBundle', 'Magento_Catalog/js/price-utils', 'algoliaCommon', 'algoliaInsights', 'algoliaHooks'], function ($, algoliaBundle, priceUtils) {
  $(function ($) {
    if (typeof algoliaConfig === 'undefined' || !algoliaConfig.instant.enabled || !(algoliaConfig.isSearchPage || !algoliaConfig.autocomplete.enabled)) {
      return;
    }
    if ($(algoliaConfig.instant.selector).length <= 0) {
      throw '[Algolia] Invalid instant-search selector: ' + algoliaConfig.instant.selector;
    }
    if (algoliaConfig.autocomplete.enabled && $(algoliaConfig.instant.selector).find(algoliaConfig.autocomplete.selector).length > 0) {
      throw '[Algolia] You can\'t have a search input matching "' + algoliaConfig.autocomplete.selector + '" inside you instant selector "' + algoliaConfig.instant.selector + '"';
    }
    var findAutocomplete = algoliaConfig.autocomplete.enabled && $(algoliaConfig.instant.selector).find('#algolia-autocomplete-container').length > 0;
    if (findAutocomplete) {
      $(algoliaConfig.instant.selector).find('#algolia-autocomplete-container').remove();
    }
    if (typeof algoliaHookBeforeInstantsearchInit === 'function') {
      algolia.registerHook('beforeInstantsearchInit', algoliaHookBeforeInstantsearchInit);
    }
    if (typeof algoliaHookBeforeWidgetInitialization === 'function') {
      algolia.registerHook('beforeWidgetInitialization', algoliaHookBeforeWidgetInitialization);
    }
    if (typeof algoliaHookBeforeInstantsearchStart === 'function') {
      algolia.registerHook('beforeInstantsearchStart', algoliaHookBeforeInstantsearchStart);
    }
    if (typeof algoliaHookAfterInstantsearchStart === 'function') {
      algolia.registerHook('afterInstantsearchStart', algoliaHookAfterInstantsearchStart);
    }
    var wrapperTemplate = algoliaBundle.Hogan.compile($('#instant_wrapper_template').html());
    var instant_selector = "#instant-search-bar";
    var div = document.createElement('div');
    $(div).addClass('algolia-instant-results-wrapper');
    $(algoliaConfig.instant.selector).addClass('algolia-instant-replaced-content');
    $(algoliaConfig.instant.selector).wrap(div);
    $('.algolia-instant-results-wrapper').append('<div class="algolia-instant-selector-results"></div>');
    $('.algolia-instant-selector-results').html(wrapperTemplate.render({
      second_bar: algoliaConfig.instant.enabled,
      findAutocomplete: findAutocomplete,
      config: algoliaConfig.instant,
      translations: algoliaConfig.translations
    })).show();
    var ruleContexts = ['magento_filters', ''];
    if (algoliaConfig.request.categoryId.length > 0) {
      ruleContexts.push('magento-category-' + algoliaConfig.request.categoryId);
    }
    if (algoliaConfig.request.landingPageId.length > 0) {
      ruleContexts.push('magento-landingpage-' + algoliaConfig.request.landingPageId);
    }
    var searchClient = algoliaBundle.algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
    var indexName = algoliaConfig.indexName + '_products';
    var searchParameters = {
      hitsPerPage: algoliaConfig.hitsPerPage,
      ruleContexts: ruleContexts
    };
    var instantsearchOptions = {
      searchClient: searchClient,
      indexName: indexName,
      searchFunction: function (helper) {
        if (helper.state.query === '' && !algoliaConfig.isSearchPage) {
          $('.algolia-instant-replaced-content').show();
          $('.algolia-instant-selector-results').hide();
        } else {
          helper.search();
          $('.algolia-instant-replaced-content').hide();
          $('.algolia-instant-selector-results').show();
        }
      },
      routing: window.routing
    };
    if (algoliaConfig.request.path.length > 0 && window.location.hash.indexOf('categories.level0') === -1) {
      if (algoliaConfig.areCategoriesInFacets === false) {
        searchParameters['facetsRefinements'] = {};
        searchParameters['facetsRefinements']['categories.level' + algoliaConfig.request.level] = [algoliaConfig.request.path];
      }
    }
    if (algoliaConfig.instant.isVisualMerchEnabled && algoliaConfig.isCategoryPage) {
      searchParameters.filters = `${algoliaConfig.instant.categoryPageIdAttribute}:"${algoliaConfig.request.path.replace(/"/g, '\\"')}"`;
    }
    instantsearchOptions = algolia.triggerHooks('beforeInstantsearchInit', instantsearchOptions, algoliaBundle);
    var search = algoliaBundle.instantsearch(instantsearchOptions);
    search.client.addAlgoliaAgent('Magento2 integration (' + algoliaConfig.extensionVersion + ')');
    algoliaConfig.sortingIndices.unshift({
      name: indexName,
      label: algoliaConfig.translations.relevance
    });
    var attributes = [];
    $.each(algoliaConfig.facets, function (i, facet) {
      var name = facet.attribute;
      if (name === 'categories') {
        name = 'categories.level0';
      }
      if (name === 'price') {
        name = facet.attribute + algoliaConfig.priceKey;
      }
      attributes.push({
        name: name,
        label: facet.label ? facet.label : facet.attribute
      });
    });
    var allWidgetConfiguration = {
      infiniteHits: {},
      hits: {},
      configure: searchParameters,
      custom: [{
        getWidgetSearchParameters: function (searchParameters) {
          if (algoliaConfig.request.query.length > 0 && location.hash.length < 1) {
            return searchParameters.setQuery(algolia.htmlspecialcharsDecode(algoliaConfig.request.query));
          }
          return searchParameters;
        },
        init: function (data) {
          var page = data.helper.state.page;
          if (algoliaConfig.request.refinementKey.length > 0) {
            data.helper.toggleRefine(algoliaConfig.request.refinementKey, algoliaConfig.request.refinementValue);
          }
          if (algoliaConfig.isCategoryPage) {
            data.helper.addNumericRefinement('visibility_catalog', '=', 1);
          } else {
            data.helper.addNumericRefinement('visibility_search', '=', 1);
          }
          data.helper.setPage(page);
        },
        render: function (data) {
          if (!algoliaConfig.isSearchPage) {
            if (data.results.query.length === 0 && data.results.nbHits === 0) {
              $('.algolia-instant-replaced-content').show();
              $('.algolia-instant-selector-results').hide();
            } else {
              $('.algolia-instant-replaced-content').hide();
              $('.algolia-instant-selector-results').show();
            }
          }
        }
      }, {
        suggestions: [],
        init: function () {
          if (algoliaConfig.showSuggestionsOnNoResultsPage) {
            var $this = this;
            $.each(algoliaConfig.popularQueries.slice(0, Math.min(4, algoliaConfig.popularQueries.length)), function (i, query) {
              query = $('<div>').html(query).text();
              $this.suggestions.push('<a href="' + algoliaConfig.baseUrl + '/catalogsearch/result/?q=' + encodeURIComponent(query) + '">' + query + '</a>');
            });
          }
        },
        render: function (data) {
          if (data.results.hits.length === 0) {
            var content = '<div class="no-results">';
            content += '<div><b>' + algoliaConfig.translations.noProducts + ' "' + $("<div>").text(data.results.query).html() + '</b>"</div>';
            content += '<div class="popular-searches">';
            if (algoliaConfig.showSuggestionsOnNoResultsPage && this.suggestions.length > 0) {
              content += '<div>' + algoliaConfig.translations.popularQueries + '</div>' + this.suggestions.join(', ');
            }
            content += '</div>';
            content += algoliaConfig.translations.or + ' <a href="' + algoliaConfig.baseUrl + '/catalogsearch/result/?q=__empty__">' + algoliaConfig.translations.seeAll + '</a>';
            content += '</div>';
            $('#instant-empty-results-container').html(content);
          } else {
            $('#instant-empty-results-container').html('');
          }
        }
      }],
      stats: {
        container: '#algolia-stats',
        templates: {
          text: function (data) {
            var hoganTemplate = algoliaBundle.Hogan.compile($('#instant-stats-template').html());
            data.first = data.page * data.hitsPerPage + 1;
            data.last = Math.min(data.page * data.hitsPerPage + data.hitsPerPage, data.nbHits);
            data.seconds = data.processingTimeMS / 1000;
            data.translations = window.algoliaConfig.translations;
            return hoganTemplate.render(data);
          }
        }
      },
      sortBy: {
        container: '#algolia-sorts',
        items: algoliaConfig.sortingIndices.map(function (sortingIndice) {
          return {
            label: sortingIndice.label,
            value: sortingIndice.name
          };
        })
      },
      currentRefinements: {
        container: '#current-refinements',
        templates: {
          item: $('#current-refinements-template').html()
        },
        includedAttributes: attributes.map(attribute => {
          if (attribute.name.indexOf('categories') === -1 || !algoliaConfig.isCategoryPage) return attribute.name;
        }),
        transformItems: items => {
          return items.filter(item => {
            return !algoliaConfig.isCategoryPage || item.refinements.filter(refinement => refinement.value !== algoliaConfig.request.path).length;
          }).map(item => {
            const attribute = attributes.filter(_attribute => {
              return item.attribute === _attribute.name;
            })[0];
            if (!attribute) return item;
            item.label = attribute.label;
            item.refinements.forEach(function (refinement) {
              if (refinement.type !== 'hierarchical') return refinement;
              const levels = refinement.label.split(algoliaConfig.instant.categorySeparator);
              const lastLevel = levels[levels.length - 1];
              refinement.label = lastLevel;
            });
            return item;
          });
        }
      },
      clearRefinements: {
        container: '#clear-refinements',
        templates: {
          resetLabel: algoliaConfig.translations.clearAll
        },
        includedAttributes: attributes.map(function (attribute) {
          if (!(algoliaConfig.isCategoryPage && attribute.name.indexOf('categories') > -1)) {
            return attribute.name;
          }
        }),
        cssClasses: {
          button: ['action', 'primary']
        },
        transformItems: function (items) {
          return items.map(function (item) {
            var attribute = attributes.filter(function (_attribute) {
              return item.attribute === _attribute.name;
            })[0];
            if (!attribute) return item;
            item.label = attribute.label;
            return item;
          });
        }
      },
      queryRuleCustomData: {
        container: '#algolia-banner',
        templates: {
          default: '{{#items}} {{#banner}} {{{banner}}} {{/banner}} {{/items}}'
        }
      }
    };
    if (algoliaConfig.instant.isSearchBoxEnabled) {
      allWidgetConfiguration.searchBox = {
        container: instant_selector,
        placeholder: algoliaConfig.translations.searchFor,
        showSubmit: false,
        queryHook: function (inputValue, search) {
          if (algoliaConfig.isSearchPage && algoliaConfig.request.categoryId.length <= 0 && algoliaConfig.request.landingPageId.length <= 0) {
            $(".page-title-wrapper span.base").html(algoliaConfig.translations.searchTitle + ": '" + algolia.htmlspecialcharsDecode(inputValue) + "'");
          }
          return search(inputValue);
        }
      };
    }
    if (algoliaConfig.instant.infiniteScrollEnabled === true) {
      allWidgetConfiguration.infiniteHits = {
        container: '#instant-search-results-container',
        templates: {
          empty: '',
          item: $('#instant-hit-template').html(),
          showMoreText: algoliaConfig.translations.showMore
        },
        cssClasses: {
          loadPrevious: ['action', 'primary'],
          loadMore: ['action', 'primary']
        },
        transformItems: function (items) {
          return items.map(function (item) {
            item.__indexName = search.helper.lastResults.index;
            item = transformHit(item, algoliaConfig.priceKey, search.helper);
            item.isAddToCartEnabled = algoliaConfig.instant.isAddToCartEnabled;
            return item;
          });
        },
        showPrevious: true,
        escapeHits: true
      };
      delete allWidgetConfiguration.hits;
    } else {
      allWidgetConfiguration.hits = {
        container: '#instant-search-results-container',
        templates: {
          empty: '',
          item: $('#instant-hit-template').html()
        },
        transformItems: function (items, _ref) {
          let results = _ref.results;
          if (results.nbPages <= 1 && algoliaConfig.instant.hidePagination === true) {
            document.getElementById('instant-search-pagination-container').style.display = "none";
          } else {
            document.getElementById('instant-search-pagination-container').style.display = "block";
          }
          return items.map(function (item) {
            item.__indexName = search.helper.lastResults.index;
            item = transformHit(item, algoliaConfig.priceKey, search.helper);
            item.isAddToCartEnabled = algoliaConfig.instant.isAddToCartEnabled;
            item.algoliaConfig = window.algoliaConfig;
            return item;
          });
        }
      };
      allWidgetConfiguration.pagination = {
        container: '#instant-search-pagination-container',
        showFirst: false,
        showLast: false,
        showNext: true,
        showPrevious: true,
        totalPages: 1000,
        templates: {
          previous: algoliaConfig.translations.previousPage,
          next: algoliaConfig.translations.nextPage
        }
      };
      delete allWidgetConfiguration.infiniteHits;
    }
    const customAttributeFacet = {
      categories: function (facet, templates) {
        const hierarchical_levels = [];
        for (let l = 0; l < 10; l++) {
          hierarchical_levels.push('categories.level' + l.toString());
        }
        const hierarchicalMenuParams = {
          container: facet.wrapper.appendChild(createISWidgetContainer(facet.attribute)),
          attributes: hierarchical_levels,
          separator: algoliaConfig.instant.categorySeparator,
          templates: templates,
          showParentLevel: true,
          limit: algoliaConfig.maxValuesPerFacet,
          rootPath: algoliaConfig.request.path,
          sortBy: ['name:asc'],
          transformItems(items) {
            return algoliaConfig.isCategoryPage ? items.map(item => {
              return _objectSpread(_objectSpread({}, item), {}, {
                categoryUrl: algoliaConfig.instant.isCategoryNavigationEnabled ? algoliaConfig.request.childCategories[item.value]['url'] : ''
              });
            }) : items;
          }
        };
        hierarchicalMenuParams.templates.item = '' + '<a class="{{cssClasses.link}} {{#isRefined}}{{cssClasses.link}}--selected{{/isRefined}}" href="{{categoryUrl}}">{{label}}' + ' ' + '<span class="{{cssClasses.count}}">{{#helpers.formatNumber}}{{count}}{{/helpers.formatNumber}}</span>' + '</a>';
        hierarchicalMenuParams.panelOptions = {
          templates: {
            header: '<div class="name">' + (facet.label ? facet.label : facet.attribute) + '</div>'
          },
          hidden: function (_ref2) {
            let items = _ref2.items;
            return !items.length;
          }
        };
        return ['hierarchicalMenu', hierarchicalMenuParams];
      }
    };
    window.getFacetWidget = function (facet, templates) {
      var panelOptions = {
        templates: {
          header: '<div class="name">' + (facet.label ? facet.label : facet.attribute) + '</div>'
        },
        hidden: function (options) {
          if (options.results.nbPages <= 1 && algoliaConfig.instant.hidePagination === true) {
            document.getElementById('instant-search-pagination-container').style.display = "none";
          } else {
            document.getElementById('instant-search-pagination-container').style.display = "block";
          }
          if (!options.results) return true;
          switch (facet.type) {
            case 'conjunctive':
              var facetsNames = options.results.facets.map(function (f) {
                return f.name;
              });
              return facetsNames.indexOf(facet.attribute) === -1;
            case 'disjunctive':
              var disjunctiveFacetsNames = options.results.disjunctiveFacets.map(function (f) {
                return f.name;
              });
              return disjunctiveFacetsNames.indexOf(facet.attribute) === -1;
            default:
              return false;
          }
        }
      };
      if (facet.type === 'priceRanges') {
        delete templates.item;
        return ['rangeInput', {
          container: facet.wrapper.appendChild(createISWidgetContainer(facet.attribute)),
          attribute: facet.attribute,
          templates: $.extend({
            separatorText: algoliaConfig.translations.to,
            submitText: algoliaConfig.translations.go
          }, templates),
          cssClasses: {
            root: 'conjunctive'
          },
          panelOptions: panelOptions
        }];
      }
      if (facet.type === 'conjunctive') {
        var refinementListOptions = {
          container: facet.wrapper.appendChild(createISWidgetContainer(facet.attribute)),
          attribute: facet.attribute,
          limit: algoliaConfig.maxValuesPerFacet,
          operator: 'and',
          templates: templates,
          sortBy: ['count:desc', 'name:asc'],
          cssClasses: {
            root: 'conjunctive'
          },
          panelOptions: panelOptions
        };
        refinementListOptions = addSearchForFacetValues(facet, refinementListOptions);
        return ['refinementList', refinementListOptions];
      }
      if (facet.type === 'disjunctive') {
        var refinementListOptions = {
          container: facet.wrapper.appendChild(createISWidgetContainer(facet.attribute)),
          attribute: facet.attribute,
          limit: algoliaConfig.maxValuesPerFacet,
          operator: 'or',
          templates: templates,
          sortBy: ['count:desc', 'name:asc'],
          panelOptions: panelOptions,
          cssClasses: {
            root: 'disjunctive'
          }
        };
        refinementListOptions = addSearchForFacetValues(facet, refinementListOptions);
        return ['refinementList', refinementListOptions];
      }
      if (facet.type === 'slider') {
        delete templates.item;
        return ['rangeSlider', {
          container: facet.wrapper.appendChild(createISWidgetContainer(facet.attribute)),
          attribute: facet.attribute,
          templates: templates,
          pips: false,
          panelOptions: panelOptions,
          tooltips: {
            format: function (formattedValue) {
              return facet.attribute.match(/price/) === null ? parseInt(formattedValue) : priceUtils.formatPrice(formattedValue, algoliaConfig.priceFormat);
            }
          }
        }];
      }
    };
    var wrapper = document.getElementById('instant-search-facets-container');
    $.each(algoliaConfig.facets, function (i, facet) {
      if (facet.attribute.indexOf("price") !== -1) facet.attribute = facet.attribute + algoliaConfig.priceKey;
      facet.wrapper = wrapper;
      var templates = {
        item: $('#refinements-lists-item-template').html()
      };
      var widgetInfo = customAttributeFacet[facet.attribute] !== undefined ? customAttributeFacet[facet.attribute](facet, templates) : getFacetWidget(facet, templates);
      var widgetType = widgetInfo[0],
        widgetConfig = widgetInfo[1];
      if (typeof allWidgetConfiguration[widgetType] === 'undefined') {
        allWidgetConfiguration[widgetType] = [widgetConfig];
      } else {
        allWidgetConfiguration[widgetType].push(widgetConfig);
      }
    });
    if (algoliaConfig.analytics.enabled) {
      if (typeof algoliaAnalyticsPushFunction !== 'function') {
        var algoliaAnalyticsPushFunction = function (formattedParameters, state, results) {
          var trackedUrl = '/catalogsearch/result/?q=' + state.query + '&' + formattedParameters + '&numberOfHits=' + results.nbHits;
          if (typeof window.ga !== 'undefined') {
            window.ga('set', 'page', trackedUrl);
            window.ga('send', 'pageView');
          }
        };
      }
      allWidgetConfiguration['analytics'] = {
        pushFunction: algoliaAnalyticsPushFunction,
        delay: algoliaConfig.analytics.delay,
        triggerOnUIInteraction: algoliaConfig.analytics.triggerOnUiInteraction,
        pushInitialSearch: algoliaConfig.analytics.pushInitialSearch
      };
    }
    allWidgetConfiguration = algolia.triggerHooks('beforeWidgetInitialization', allWidgetConfiguration, algoliaBundle);
    $.each(allWidgetConfiguration, function (widgetType, widgetConfig) {
      if (Array.isArray(widgetConfig) === true) {
        $.each(widgetConfig, function (i, widgetConfig) {
          addWidget(search, widgetType, widgetConfig);
        });
      } else {
        addWidget(search, widgetType, widgetConfig);
      }
    });
    if (algoliaConfig.instant.isAddToCartEnabled) {
      search.on('render', () => {
        const cartForms = document.querySelectorAll('[data-role="tocart-form"]');
        cartForms.forEach((form, i) => {
          const ts = Date.now();
          form.addEventListener('submit', e => {
            const url = `${algoliaConfig.request.url}${window.location.search}`;
            e.target.elements[algoliaConfig.instant.addToCartParams.redirectUrlParam].value = AlgoliaBase64.mageEncode(url);
          });
        });
      });
    }
    var isStarted = false;
    function startInstantSearch() {
      if (isStarted === true) {
        return;
      }
      search = algolia.triggerHooks('beforeInstantsearchStart', search, algoliaBundle);
      search.start();
      search = algolia.triggerHooks('afterInstantsearchStart', search, algoliaBundle);
      isStarted = true;
    }
    startInstantSearch();
  });
  function addWidget(search, type, config) {
    if (type === 'custom') {
      search.addWidgets([config]);
      return;
    }
    var widget = algoliaBundle.instantsearch.widgets[type];
    if (config.panelOptions) {
      widget = algoliaBundle.instantsearch.widgets.panel(config.panelOptions)(widget);
      delete config.panelOptions;
    }
    if (type === "rangeSlider" && config.attribute.indexOf("price.") < 0) {
      config.panelOptions = {
        hidden(options) {
          return options.range.min === 0 && options.range.max === 0;
        }
      };
      widget = algoliaBundle.instantsearch.widgets.panel(config.panelOptions)(widget);
      delete config.panelOptions;
    }
    search.addWidgets([widget(config)]);
  }
  function addSearchForFacetValues(facet, options) {
    if (facet.searchable === '1') {
      options.searchable = true;
      options.searchableIsAlwaysActive = false;
      options.searchablePlaceholder = algoliaConfig.translations.searchForFacetValuesPlaceholder;
      options.templates = options.templates || {};
      options.templates.searchableNoResults = '<div class="sffv-no-results">' + algoliaConfig.translations.noResults + '</div>';
    }
    return options;
  }
});

