define('TescoMobile_Catalog/js/new-date',[], function () {
  'use strict';

  return {
    isProductNew: (news_date_from, news_date_to) => {
      const dateFrom = news_date_from ? Date.parse(news_date_from) : null;
      const dateTo = news_date_to ? Date.parse(news_date_to) : null;
      const currentDate = Date.now();
      if (!dateFrom && !dateTo) {
        return false;
      }
      if (!dateFrom) {
        return currentDate <= dateTo;
      }
      if (!dateTo) {
        return dateFrom <= currentDate;
      }
      return dateFrom <= currentDate && currentDate <= dateTo;
    }
  };
});

