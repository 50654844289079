define('TescoMobile_ClubCard/js/template/autocomplete/products-mixin',['mage/utils/wrapper', 'mage/translate'], function (wrapper, $t) {
  'use strict';

  return function (productHtml) {
    var _algoliaConfig$autoco;
    if (!((_algoliaConfig$autoco = algoliaConfig.autocomplete) !== null && _algoliaConfig$autoco !== void 0 && _algoliaConfig$autoco.isClubCardEnabled)) {
      return productHtml;
    }
    const getClubCardHtml = (item, html) => {
      if (item.__payg || !item.clubcard || !item.clubcard.is_clubcard) {
        return '';
      }
      const clubcard = item.clubcard,
        __is_simo = item.__is_simo;
      return html`
                <div class="clubcard-stripe ${__is_simo && clubcard.is_frozen_pricing ? 'has-frozen' : ''}">
                    <div class="clubcard-stripe__logo">
                        <span>${$t('Clubcard Price')}</span>
                    </div>
                    ${clubcard.is_lowest_price ? html`
                            <div class="clubcard-stripe__info"
                                 ${__is_simo ? html`data-clubcard="${clubcard.lowest_price}"` : ''}>
                                <div>
                                    ${__is_simo ? html`${clubcard.lowest_price} <span>${$t('a month')}</span>` : `${$t('%1 a month').replace('%1', clubcard.lowest_price)}`}
                                </div>
                                ${__is_simo && clubcard.is_frozen_pricing ? html`
                                    <div class="frozen-prices">${$t('Frozen for your contract')}</div>` : ''}
                            </div>` : html`
                            <div class="clubcard-stripe__info">${$t('Clubcard Prices available on selected deals')}
                            </div>`}
                </div>`;
    };
    productHtml.getProductContainerHtml = wrapper.wrapSuper(productHtml.getProductContainerHtml, function (item, html) {
      return html`${this._super(item, html)}${getClubCardHtml(item, html)}`;
    });
    return productHtml;
  };
});

