define('TescoMobile_Catalog/js/price-format-precision-mixin',['jquery', 'ko', 'mage/utils/wrapper'], function ($, ko, wrapper) {
  'use strict';

  function formatPriceCustom(amount, format, isShowSign) {
    const value = ko.isObservable(amount) ? amount() : amount;
    const desiredPrecision = value % 1 !== 0 ? 2 : 0;
    format = format || {};
    format.decimalSymbol = '.';
    format.integerRequired = false;
    format.pattern = "£%s";
    format.precision = desiredPrecision;
    format.requiredPrecision = desiredPrecision;
    return this._super(value, format, isShowSign);
  }
  return function (priceUtils) {
    priceUtils.formatPrice = wrapper.wrapSuper(priceUtils.formatPrice, formatPriceCustom);
    priceUtils.formatPriceLocale = wrapper.wrapSuper(priceUtils.formatPriceLocale, formatPriceCustom);
    return priceUtils;
  };
});

